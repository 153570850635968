footer {
    div.disclosures,div.language-selector {
        display: none;
    }
    form.navbar-form {
        display: none;
    }
}
#promo_ticker, #promo_drawer, .promoBanner.bri-style {
    display:none !important;
}
.column-item {
    text-align: left;
}
.header-fpr{
    background-color: #F4F4F4;
    max-width: 1440px;
    margin: 0 auto;
    padding-left: 56px;
    padding-right: 56px;
    padding-top: 12px;
    padding-bottom: 8px;
    @media screen and (max-width:768px){
        padding-left: 20px;
        padding-right: 20px;
    }
    &-section{
        display: flex;
        justify-content: flex-end;
        @media screen and (max-width:768px){
            justify-content: flex-start;
        }
        &_icon{
            margin-right: 5px;
        }
        p{
            line-height: 1.6em;
            span{
                font-weight: bold;
            }
        }
    }
}