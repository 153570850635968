/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
/**
* CSS Reset
*/
/*
html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a:-webkit-any-link {
  text-decoration: none;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

$images-dir: "^images/";

.accordion-title:focus {
  background-color: transparent;
}


// call out additional styles to fix close button overlaying text
.callout {
  & > *:first-child:not(.close-button) {
    display: inline-flex;
    margin-right: 1rem;
  }
}

.bundle-qty {
  vertical-align: middle;
  @media not screen and (max-width: 40em) {
    max-width: 4rem;
  }
}

@media screen and (max-width: 58.875em) {
  .hideOnMobile {
    display: none;
  }
}

.promo-large {
  position: relative;
  overflow: hidden;
  height: 85vh;
  background-position: 34% 0;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 640px) {
    height: 0;
    padding-top: 41.6667%;
  }

  img.promo-img {
    position: absolute;
    top: 0%;
    height: 100%;

    @media (max-width: 640px) {
      height: 100%;
      max-width: none;
    }
  }

  .promo-border {
    outline: 3px solid #fff;
    outline-offset: -20px;
  }
}

.promo-large-message {
  position: absolute;
  top: 10%;
  left: 10%;
  margin-right: 10%;

  .promo-large-text {
    font-size: 1.625rem; 
    line-height: 1.4;
    font-weight: 600;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  }
}


.promo-medium {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6c6c6c;
  height: 40px;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease-in-out;

  img {
    position: absolute;
    top: 0%;
    left: 0;

    height: 100%;
  }

  @media (min-width: 640px) {
    display: static;
    position: relative;
    height: 0;
    padding-top: 16.6667%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media (max-width: 640px) {
    position: relative;
  }
}

.promo-medium-message {
  color: #fff;

  @media (min-width: 39.9375em) {
    width: 100%;
    position: absolute;
    top: 40%;
    left: 0;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  }

  @media screen and (min-width: 40em) and (max-width: 63.9375em) and (orientation: landscape) {
    top: 35%;
  }

  @media (min-width: 63.9375em) {
    font-size: 1.875rem; 
  }

  > p {
    margin: 0;
  }
}

 
.promo-small-container {
  position: relative;
  width: 100%;

  //In promotion image profile, small rendition has dimension ( 285px by 297px)
  max-width: 285px;
  max-height: 297px;

  .promo-small {
    position: relative;
    height: 0;
    padding-top: 75.1%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    justify-content: center;
    align-items: center;

    img {
      position: absolute;
      top: 0%;
      display: block;
      height: auto;
      overflow: hidden;
    }

    .promo-border {
      outline: 3px solid black;
      outline-offset: -27px;
    }

    .promo-small-message {
      position: absolute;
      padding: 6px 10px;
      width: 100%;
      font-size: 1.625rem;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      > p {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .promo-text-small {
    font-size: 1.375rem;  
    font-weight: 700;
    line-height: 1.2;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    color: black;

    @media (min-width: 900px) {
      font-size: 1.625rem;  
    }
  }
}

.wch-hero-image {
  overflow: hidden;
  position: relative;
  text-align: center;

  @media (max-width: 639px) {
    position: relative;
    height: 85vh;
    overflow: hidden;
  }

  .section {
   
    padding-top: 0;
    display: flex;
  }

  &.wch-hero-image-message-right .hero-message > div {
    text-align: right;
  }

  &.wch-hero-image-message-left .hero-message > div {
    text-align: left;
  }

  &.wch-hero-image-message-center .hero-message > div {
    text-align: center;
  }

  img {
    max-width: 100%;
    transition: transform 0.3s ease-in;

    position: relative;
    z-index: 1;

    @media (max-width: 639px) {
      transform: translate3d(-18%, 0, 0);
      height: 100%;
      max-width: none;
    }

    @media (max-width: 39.9375em) {
      width: auto;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .foreground-image {
    position: absolute;
    z-index: 2;
  }
}

.hero-message {
  top: 0;
  right: auto;
  left: auto; 
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  max-width: 1200px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5),
    0 -1px 0 rgba(255, 255, 255, 0.5), 1px 0 0 rgba(255, 255, 255, 0.5),
    -1px 0 0 rgba(255, 255, 255, 0.5);

  position: absolute;
  z-index: 2;
  margin-left: 1rem;
  margin-right: 1rem;

  @media (max-width: 39.9375em) {
    justify-content: flex-start;
    padding-top: 22vh;
  }

  > div {
    padding: 0 1rem;

    @media (min-width: 780px) {
      padding: 0 2rem;
    }
    @media (min-width: 1024px) {
      padding: 0 3rem;
    }
  }

  .text-hero-left {
    text-align: left;
  }

  .text-hero-right {
    text-align: right;
  }

  .text-hero-center {
    text-align: center;
  }
}

h1.text-hero {
  font-size: 2rem;
  line-height: 1;
  color: #000;
  font-family:    Arial, "Lucida Grande",
    sans-serif;

  font-weight: 600;

  @media (min-width: 640px) {
    font-size: 2.5rem;
    line-height: 1.26;
  }

  @media (min-width: 1024px) {
    font-size: 4rem;

    h1 {
      max-width: 1200px;
    }
    > .button {
      margin-top: 64px;
    }
  }
}

.text-left {
  text-align: left;
}


img {
  vertical-align: inherit;
}

.swatch-image {
  vertical-align: middle;
}


.increaseTextContrast {
  .MuiTypography-root {
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 30px white;
    display: inline-block;
    border-radius: 6px;
    color: black;
  }
}

.textShadow {
  .MuiTypography-root {
    text-shadow: 0 0 0.3em white;
    color: black;
  }
}
.marketing-heroImage {
  overflow: hidden;
  height: 400px;
  background-size: cover;

  @media (min-width: 800px) {
    height: 500px;
  }

  @media (min-width: 1100px) {
    height: 600px;
  }

  @media (min-width: 1900px) {
    height: 700px;
  }

  .MuiTypography-h2 {
    font-weight: 400;
  }

  .marketing-text {
    left: 20px;

    @media (min-width: 600px) {
      left: 40px;
    }

    @media (min-width: 1000px) {
      left: 60px;
    }
  }

  img {
    opacity: 0.75;

    @media (min-width: 640px) {
      opacity: 0.8;
    }

    @media (min-width: 780px) {
      opacity: 0.85;
    }

    @media (min-width: 900px) {
      opacity: 0.9;
    }
  }
}

.marketing-fullscreen-banner {
  img {
    position: absolute;
  }
}

.HomeHeroContent {
  .marketing-text {
    background: rgba(255, 255, 255, 0.6);
    border: 2px solid white;
    border-radius: 6px;

    @media (max-width: 799px) {
      width: 100%;
      max-width: 100%;
      left: 0;
    }
  }

  .MuiTypography-root {
    display: inline;
  }

  .MuiTypography-h2 {
    font-weight: 700;
  }

  .marketing-text {
    padding: 20px 24px;
    left: 0;

    @media (min-width: 960px) {
      padding: 24px 30px;
      left: 40px;
    }

    @media (min-width: 1320px) {
      left: 0;
    }
  }
}

.FreeDeliveryContent {
  height: 200px;
  border-radius: 6px;
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.1),
    0px 2px 1px 0px rgba(0, 0, 0, 0.25);

  @media (min-width: 600px) {
    height: 240px;

    .marketing-text {
      padding: 16px 32px;
    }
  }
  @media (max-width: 599px) {
    .marketing-text {
      padding: 8px 16px;
    }
  }

  img {
    opacity: 0.4;
  }

  .marketing-text {
    width: 600px;
    max-width: 100%;
    text-align: center;
    padding: 8px 16px;
    font-weight: 500;
    color: black;

    @media (min-width: 600px) {
      padding: 16px 32px;
    }
  }

  .MuiTypography-h4 {
    font-weight: 500;
  }
}

.PercentOffContent {
  display:none;
  border-radius: 6px;
  box-shadow: 2px 3px 3px #ddd;
  margin-top: 16px;
  background: white;

  .MuiGrid-item:last-child {
    min-height: 120px;
    position: relative;
    margin-top: 8px;
  }

  .MuiTypography-h4 {
    font-weight: 500;
  }
}

.SapphirePercentOffContent {
  color: white;
  height: 200px;
  border-radius: 6px;
  font-weight: 500;

  @media (min-width: 600px) {
    height: 400px;
  }

  .marketing-text {
    max-width: 350px;
  }
}

.SapphireOrderManagement {
  height: 200px;
  border-radius: 6px;

  @media (min-width: 600px) {
    height: 240px;

    .marketing-text {
      padding: 16px 32px;
    }
  }
  @media (max-width: 599px) {
    .marketing-text {
      padding: 8px 16px;
    }
  }

  img {
    opacity: 0.9;
  }

  .marketing-text {
    width: 600px;
    max-width: 90%;
    padding: 0 16px 8px;
    font-weight: 500;
    color: black;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 6px;

    @media (min-width: 600px) {
      padding: 8px 20px 16px;
    }
  }

  .MuiTypography-h4 {
    font-weight: 400;
  }
}

.marketing-backgroundImage {
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    vertical-align: middle;
  }
}

.marketing-content {
  position: relative;
  overflow: hidden;
}

.marketing-text {
  &.marketing-text-vertical-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  &.marketing-text-horizontal-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  &.marketing-text-horizontal-center.marketing-text-vertical-center {
    transform: translateX(-50%) translateY(-50%);
  }
}

.category-recommendation {
  img {
    display: block;
  }
}

.MuiPopover-root {
  .StyledTableFilterMenu {
    top: 50% !important;
    left: 50% !important;
  }
}

.table-filter {
  .MuiTypography-body2 {
    font-weight: 600;
    font-size: 1.05rem;
  }
}

.bundle-error {
  .MuiTypography-body2.error {
    font-weight: 600;
    font-size: 0.8rem;
  }
}

li.MuiBreadcrumbs-li:last-child {
  overflow: hidden;
}

.table-tablet {
  @media screen and (min-width: 40em) and (max-width: 64em) {
    > thead {
      > tr {
        > th {
          padding: 0.2rem;
        }
      }
    }
    > tbody {
      > tr {
        > td {
          padding: 0.2rem;
        }
      }
    }
  }
}

.cprof-cr8-acrdn {
  > .MuiAccordionSummary-content {
    > .MuiBox-root {
      flex-wrap: nowrap;
    }
  }
}

.shipment-group-summary {
  // panel-text
  > .MuiAccordionSummary-content {
    > .shipment-group-summary-text {
      margin-top: 0.25rem;
      p {
        word-break: break-word;
        white-space: normal;
      }
    }
  }
  // panel-expander (icon+text)
  > .MuiAccordionSummary-expandIcon.MuiButtonBase-root.MuiIconButton-root {
    margin-top: 0.25rem;

    > .MuiIconButton-label {
      > .accordion-show-summary,
      .accordion-show-expanded {
        @media screen and (max-width: 40em) {
          display: none;
        }
      }
    }
  }
}

p.MuiTypography-root.StyledNumberInput {
  @media screen and (min-width: 40em) {
    max-width: 10rem;
  }
}

.break-word {
  word-break: break-word;
}

.text-right {
  text-align: right;
}
*/

.fgx-brand-css * {
  .link--with-arrow.shop-parts-link {
    color: #00095b;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    margin-top: 16px;
    margin-left: 10px;
    font-style: normal;
    padding-right: 0px;
    background: none;
    display: inline-block;

    svg {
      font-size: 24px;
      top: 8px;
      position: relative;
    }
  }

  .button.shop-chargers {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    margin-top: 16px;
    font-style: normal;
    color: #fff !important;
  }
}

.breadcrumbs--product {
  @media screen and (max-width: 959px) {
    display: none;
  }

  .breadcrumbs {
    margin: 0;
    padding-left: 0 !important;

    ul,
    ol {
      li {

        span,
        a {
          font-family: var(--font-primary) !important;
          color: #6e6e6e;
          font-size: 15px;
          font-weight: 400;
          font-stretch: condensed;
          line-height: 24px;
          letter-spacing: 0.8px;
          line-height: 18px;
          /* text-transform: uppercase;*/
        }
      }

      li.MuiBreadcrumbs-separator {
        margin-right: 5px;
        margin-left: 5px;

        svg {
          font-size: 1.9rem;
        }
      }
    }
  }
}

.breadcrumbs--product {
  padding: 25px 25px 15px 5px;
}

.breadcrumbs--product .breadcrumbs {
  padding-left: 35px;
}

@media (max-width: 950px) {
  .breadcrumbs--product {
    display: none;
  }
}

.breacrumb-title {
  color: #4d4d4d;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.8px;
  font-stretch: condensed;
}

.product__message .product-item__suggestion .product-item__suggestion-title.rparts {
  font-size: 14px !important;
}

.product__details.rparts-link {
  .arrowicon {
    transform: rotate(360deg);
  }

  @media (min-width:1024px) {
    margin-left: 30px;
  }

}

.ItemPage {
  .MuiSnackbar-root {
    z-index: 100009;

    .MuiAlert-message {
      font-size: 1.4rem;
      font-weight: 500;
      font-family: var(--font-primary);
    }

    .MuiAlert-filledError .MuiAlert-icon {
      font-size: 2.6rem;
    }

    .MuiAlert-message a:hover {
      text-decoration: underline;
      color: #00095b;
    }

    .MuiAlert-action .MuiIconButton-sizeSmall .MuiIconButton-label svg {
      font-size: 2rem;
    }
  }

  .product__main .product__title {
    @media (max-width:520px) {
      font-size: 26px;
      line-height: 32px;
    }
  }

  .page .MuiContainer-maxWidthLg:first-child {
    max-width: 1440px;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    margin: 0 auto;
  }

}

@media (max-width: 720px) {
  .fgx-brand-css.template-product .footer-container {
    .product__atc.button {
      width: 135px;
      padding: 8px 8px;
      font-size: 17px;

      @media (max-width: 330px) {
        font-size: 13px;
      }
    }

    .footer-headings .footer-title {
      margin-right: 8px;
    }
  }
}

.cta-banner {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  background: #102b4e;
  color: #fff;
  min-height: 100px;
  padding-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.cta-banner--no-margin {
  margin: 0;
}

.cta-banner__container {
  padding-bottom: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  height: inherit;
}

.cta-banner__container .slick-track {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.cta-banner__container .slick-arrow {
  filter: invert(1);
  width: 8px;
  height: 14px;
}

@media (max-width: 480px) {
  .cta-banner__container .slick-list {
    padding: 0 !important;
    margin: 0 20px;
  }
}

@media (max-width: 1239px) {
  .cta-banner__container {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 480px) {
  .cta-banner__container {
    padding-left: 0;
    padding-right: 0;
  }
}

.cta-banner {
  .slick--left-and-right-arrows.carousel-enabled {
    padding-bottom: 42px;
    padding-top: 15px;
  }

  .slick--left-and-right-arrows.no-carousel {
    padding-bottom: 22px;
    padding-top: 15px;
  }
}

.cta-banner .slick--left-and-right-arrows .slick-arrow {
  bottom: 0;
  -webkit-transform: translateY(-90%);
  -moz-transform: translateY(-90%);
  -ms-transform: translateY(-90%);
  -o-transform: translateY(-90%);
  transform: translateY(-90%);
}
.slick-slide.slidecontent {
  width:calc(25% - 0px) !important;
  display: block;
}

.cta-banner .slick--left-and-right-arrows .slick-arrow.slick-prev {
  left: 0;
  left: calc(50% - 45px);
  -webkit-transform: translateY(calc(100% + 16px)) rotate(180deg);
  -moz-transform: translateY(calc(100% + 16px)) rotate(180deg);
  -ms-transform: translateY(calc(100% + 16px)) rotate(180deg);
  -o-transform: translateY(calc(100% + 16px)) rotate(180deg);
  transform: translateY(calc(100% + 16px)) rotate(180deg);
}

@media (max-width: 1024px) {
  .cta-banner .slick--left-and-right-arrows .slick-arrow.slick-prev {
    -webkit-transform: translateY(calc(100% + 13px)) rotate(180deg);
    -moz-transform: translateY(calc(100% + 13px)) rotate(180deg);
    -ms-transform: translateY(calc(100% + 13px)) rotate(180deg);
    -o-transform: translateY(calc(100% + 13px)) rotate(180deg);
    transform: translateY(calc(100% + 13px)) rotate(180deg);
  }
}

@media (max-width: 600px) {
  .cta-banner .slick--left-and-right-arrows .slick-arrow.slick-prev {
    -webkit-transform: translateY(calc(100% + 30px)) rotate(180deg);
    -moz-transform: translateY(calc(100% + 30px)) rotate(180deg);
    -ms-transform: translateY(calc(100% + 30px)) rotate(180deg);
    -o-transform: translateY(calc(100% + 30px)) rotate(180deg);
    transform: translateY(calc(100% + 30px)) rotate(180deg);
  }
}

.cta-banner .slick--left-and-right-arrows .slick-arrow.slick-next {
  right: 0;
  right: calc(50% - 45px);
  -webkit-transform: translateY(calc(100% + 16px));
  -moz-transform: translateY(calc(100% + 16px));
  -ms-transform: translateY(calc(100% + 16px));
  -o-transform: translateY(calc(100% + 16px));
  transform: translateY(calc(100% + 16px));
}

@media (max-width: 1024px) {
  .cta-banner .slick--left-and-right-arrows .slick-arrow.slick-next {
    -webkit-transform: translateY(calc(100% + 13px));
    -moz-transform: translateY(calc(100% + 13px));
    -ms-transform: translateY(calc(100% + 13px));
    -o-transform: translateY(calc(100% + 13px));
    transform: translateY(calc(100% + 13px));
  }
}

@media (max-width: 600px) {
  .cta-banner .slick--left-and-right-arrows .slick-arrow.slick-next {
    -webkit-transform: translateY(calc(100% + 30px));
    -moz-transform: translateY(calc(100% + 30px));
    -ms-transform: translateY(calc(100% + 30px));
    -o-transform: translateY(calc(100% + 30px));
    transform: translateY(calc(100% + 30px));
  }
}

.cta-banner .slick--left-and-right-arrows .slick-arrow.slick-disabled {
  opacity: 0;
  cursor: default;
}

.cta-banner-item {
  width: 100%;
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -moz-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.cta-banner-item__left {
  width: 150px;
  padding-left: 5px;
}

@media (max-width: 1024px) {
  .cta-banner-item__left {
    width: 100%;
    padding-left: 0;
  }
}

.cta-banner-item__overview-text {
  font-family: var(--fmc-font--antenna);
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@media (max-width: 1024px) {
  .cta-banner-item__overview-text {
    text-align: center;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
}

.cta-banner-item__right {
  width: calc(100% - 150px);
}

@media (max-width: 1024px) {
  .cta-banner-item__right {
    width: 100%;
    padding-left: 0;
  }
}

.cta-banner-item__right.cta-banner-item__right--full-width {
  width: 100%;
  padding-right: 0;
  text-align: center;
}

.cta-banner-item__main-text {
  font-family: Arial, "Lucida Grande", sans-serif;
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  color: #fff;
  font-stretch: normal;
}

.cta-banner-item__main-text span {
  color: #3caeee;
}

@media (max-width: 1024px) {
  .cta-banner-item__main-text {
    text-align: center;
    font-size: 13px;
    line-height: normal;
  }
}

@media (max-width:500px) {
  .cta-banner-item__main-text {
    font-family: Arial, "Lucida Grande", sans-serif;
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    color: #fff;
    font-stretch: normal;
  }
}

.cta-banner-item__supportive-text {
  margin-top: 2px;
  font-family: Arial, "Lucida Grande", sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  color: #fff;
  font-stretch: normal;
}

.cta-banner-item__right p:last-child {
  font-family: Arial, "Lucida Grande", sans-serif;

}



.cta-banner-item__supportive-text span {
  color: #3caeee;
}

.cta-banner-item__supportive-text [data-disclosures-trigger] {
  cursor: pointer;
}

@media (max-width: 1024px) {
  .cta-banner-item__supportive-text {
    text-align: center;
    font-size: 10px;
    line-height: normal;
  }
}

.cta-banner-item__disclaimer-text {
  margin-top: 10px;
  font-family: var(--fmc-font--antenna);
  font-size: 7px;
  line-height: 8px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5px;
}

@media (max-width: 1024px) {
  .cta-banner-item__disclaimer-text {
    text-align: center;
  }
}

.cta-banner__toggle-play-container {
  display: none;
  position: absolute;
  bottom: 3px;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
}

.cta-banner__toggle-play-container.active {
  display: flex;
}

.cta-banner__toggle-play-button {
  height: 18px;
  width: 18px;
  background-position: center;
  background-size: cover;
  margin-bottom: 8px;
}

.cta-banner__toggle-play-button.play {
  background-image: url("data:image/svg+xml,%3Csvg height='512px' id='Layer_1' style='enable-background:new 0 0 512 512;' version='1.1' viewBox='0 0 512 512' width='512px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath fill='%23fff' d='M405.2,232.9L126.8,67.2c-3.4-2-6.9-3.2-10.9-3.2c-10.9,0-19.8,9-19.8,20H96v344h0.1c0,11,8.9,20,19.8,20 c4.1,0,7.5-1.4,11.2-3.4l278.1-165.5c6.6-5.5,10.8-13.8,10.8-23.1C416,246.7,411.8,238.5,405.2,232.9z'/%3E%3C/svg%3E");
}

.cta-banner__toggle-play-button.pause {
  background-image: url("data:image/svg+xml,%3Csvg height='512px' id='Layer_1' style='enable-background:new 0 0 512 512;' version='1.1' viewBox='0 0 512 512' width='512px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Cpath fill='%23fff' d='M224,435.8V76.1c0-6.7-5.4-12.1-12.2-12.1h-71.6c-6.8,0-12.2,5.4-12.2,12.1v359.7c0,6.7,5.4,12.2,12.2,12.2h71.6 C218.6,448,224,442.6,224,435.8z'/%3E%3Cpath fill='%23fff' d='M371.8,64h-71.6c-6.7,0-12.2,5.4-12.2,12.1v359.7c0,6.7,5.4,12.2,12.2,12.2h71.6c6.7,0,12.2-5.4,12.2-12.2V76.1 C384,69.4,378.6,64,371.8,64z'/%3E%3C/g%3E%3C/svg%3E");
}



.featured-content__title {
  margin-bottom: 30px;
  margin-top: 0;
  letter-spacing: 4px;
}

.cta__feature {
  position: relative;
  width: 100%;
  height: 333px;
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

@media (max-width: 480px) {
  .cta__feature {
    height: fit-content;
  }
}

.cta__feature .banner-datetime-check {
  width: 100%;
  min-height: 100%;
}


.slick--bottom-arrows {
  padding-bottom: 64px;
}

.slick--bottom-arrows .slick-arrow {
  font-size: 0px;
  width: 14px;
  height: 24px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-image: url(/themes/icons/icon-chevron-right-primary.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.slick--bottom-arrows .slick-arrow.slick-prev {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.slick--bottom-arrows .slick-arrow.slick-next {
  left: 70px;
}

.slick--bottom-arrows .slick-arrow.slick-disabled {
  opacity: 0;
  cursor: default;
}

.slick--left-and-right-arrows .slick-arrow {
  font-size: 0px;
  width: 14px;
  height: 14px;
  position: absolute;
  bottom: 42px !important;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  background-image: url(/themes/icons/icon-chevron-right-primary.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  @media (max-width:600px) {
    bottom: 56px !important;
  }
}

.slick--left-and-right-arrows .slick-arrow.slick-prev {
  left: 0;
  -webkit-transform: translateY(-50%) rotate(180deg);
  -moz-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  -o-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.slick--left-and-right-arrows .slick-arrow.slick-next {
  right: 0;
}

.slick--left-and-right-arrows .slick-arrow.slick-disabled {
  opacity: 0;
  cursor: default;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}


.btn--centered {
  margin: 0 auto;
}

.btn--rounded {
  border-radius: 20px;
}

.btn--chevron {
  position: relative;
  padding-right: 40px;
}

.btn--chevron:before,

.btn--chevron:before {
  background-image: url(/themes/icons/icon-chevron-right-primary.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0;
}

@media (min-width: 769px) {
  .btn--chevron:hover:not(:disabled):not(.btn--plain):after {
    opacity: 0;
  }

  .btn--chevron:hover:not(:disabled):not(.btn--plain):before {
    opacity: 1;
  }
}

.btn--chevron.btn--white:not(.btn--border):not(.btn--plain):after,
.btn--chevron:not(.btn--white).btn--border:after {
  background-image: url(/themes/icons/icon-chevron-right-primary.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.btn--chevron.btn--plain {
  padding-right: 50px;
  visibility: visible;
}

@media (min-width: 769px) {

  .btn--chevron.btn--plain:hover:not(:disabled):after,
  .btn--chevron.btn--plain:hover:not(:disabled):before {
    right: 25px;
  }
}

.btn--chevron-secondary {
  position: relative;
  padding-right: 46px;
}

.CartPage {
  .page .MuiContainer-maxWidthLg:first-child {
    max-width: 1440px;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    margin: 0 auto;
  }
}

.disclosures {
  width: 100%;
}

.disclosures__trigger-area {
  width: 100%;
  background: #ffffff;
}

.disclosures__trigger-text {
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  cursor: pointer;
  color: #2861a4;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: var(--fmc-font--antenna);
  font-stretch: condensed;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 300;
  text-transform: uppercase;
}

.disclosures__trigger-text img,
.disclosures__trigger-text svg {
  margin-left: 15px;
}

.disclosures__trigger-text--active img,
.disclosures__trigger-text--active svg {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.disclosures__content {
  width: 100%;
  height: 0;
  background: #f7f7f7;
  color: #4d4d4d;
  font-size: 12px;
  line-height: 16px;
  overflow-y: auto;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}

.disclosures__content p:not(:last-child) {
  margin-bottom: 5px;
}

.disclosures__content .rte a {
  color: #4d4d4d !important;
}

.disclosures__content .container,
.disclosures__content .container--sm,
.disclosures__content .container--no-padding,
.disclosures__content .container--mb-2,
.disclosures__content .kuContainer {
  padding-top: 30px;
  padding-bottom: 30px;
}

.container,
.container--mb-2,
.container--no-padding,
.container--sm,
.kuContainer {
  margin: 0 auto;
  max-width: 1440px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
}

#shopify-section-disclosures .disclosures__content--active {
  height: 100% !important;
}

@media (max-width: 768px) {
  #shopify-section-disclosures .disclosures__content--active {
    height: 100% !important;
  }
}

#shopify-section-disclosures {
  padding-top: 20px;
}

.cta-banner--hidden {
  content-visibility: hidden;
  contain-intrinsic-size: 0 0;
}

.cta-banner--visible {
  content-visibility: visible;
  contain: style layout paint;
}

.product-item {
  font-family: var(--font-primary);
}

.RedeemPointConversion {
  font-family: var(--font-primary);
  display: inline-block;
  font-size: 14px;
  text-transform: none;
  u {
    color: var(--ford-blue);
    cursor: pointer;
  }
}
div,
p,
span:not(.icon),
h1 {
  font-family: var(--font-primary) !important;
}

//Earn Module
.content-box-earn{
  margin-top: 18px;
}
.section-earn {
  display: flex;
  align-items: center;
  @media (max-width:768px) {
     display: block;
  }
  }
  .image-container-earn {
  flex: 1; 
  }
  .text-container-earn {
  flex: 1; 
  color: #00142E;
  margin-left: 10px;
  margin-bottom: 25px;
  line-height: 1.5; 
  font-family: var(--fmc-font--antenna) !important;
  @media (max-width:768px) {
      text-align: left;
      margin-top: 15px;
  }
  @media (max-width:768px) {
      font-size:12px;
  }
  }
  .Earn-Content-1 {
    font-size: 28px ;
    font-weight: 400;
    line-height: 36px; 
    font-family: var(--fmc-font--antenna) !important;
    @media (max-width:768px) {
      font-size:22px;
  }
  }
  .Earn-Content-2 {
      font-family: var(--fmc-font--antenna) !important;
      margin-top: 20px;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px; 
      letter-spacing: 1px;
      @media (max-width:768px) {
          font-size:13px;
      }
  }
  .fordreward-message-fpr{
    margin-top: 1em;
    color: rgb(0, 20, 46);
    font-size: 1em;
    a{
      
    color: rgb(0, 20, 46) !important;
    text-decoration: underline !important;
    }
  }

  .account-addressforms p.MuiFormHelperText-root.Mui-error {
    font-size: 11px;
  }

  .circular-loader {
    .MuiCircularProgress-svg{
      color:#ffff !important; 
      height:32px;
    }  
  }
  .circular-loader-footer svg {
    height:35px; 
  }
